// 定义英文语言包对象
export default {
  hotspot: "Hotspot",
  ABOUT: 'ABOUT US',
  OURMENU:'OUR MENU',
  MEDIA:"MEDIA",
  COOPERATION:'COOPERATION',
  CONTACT:"CONTACT",
  discoverNotes:"Tianlala is a tea franchise brand under PT Hui Wang Prosperity",
  aboutSecondContent:"Experience the essence of Tianlala as we invite you on a journey of discovery, where each sip tells a story of passion and excellence. From our meticulously curated selection of teas to our innovative marketing strategies, every aspect of our brand reflects our dedication to delighting our customers and exceeding their expectations.",
  bestSeller:'OUR BEST SELLER',
  bestSellerSecond:'Happy Family Bucket',
  aboutMoreBtnText:"See More Menu",
  fiveTitle:'OUR OUTLETS AROUND INDONESIA',
  fiveNotes:'Pantai Indah Kapuk, Muara Karang, Kota Tua, Mangga Besar, Pasar Baru, Taman Ratu, Tanjung Duren, Klender, Bintara, Jatiwaringin, Ciamis, Mall Artha Gading, BSD',
  gooleReview:'GOOGLE REVIEW',
  helenaN:"Helena N",
  makanan:'Makanan: 5/5 | Layanan: 5/5 | Suasana: 5/5',
  servingIce:"Serving ice cream and tea based desserts, fresh and very affordable. Consists of 4 floors, spacious place, plenty tables to sit, good service, cold Air Conditioner which is very good to have in this sunny hot PIK area.",
  about:{
    firstLineTitle:'WELCOME TO OUR FLAVOR WONDERLAND',
    firstLineTips:"Tianlala is a tea franchise brand under PT Hui Wang Prosperity",
    firstLineContent:"opened for franchise opportunities in 2015. Tianlala is a national franchise chain in China that focuses on the production and sale of fresh fruit tea. Due to its high-quality beverages, exceptional taste, and reasonably affordable prices, Tianlala quickly became a leading franchise brand in the Chinese market, with over 7,500 operational stores worldwide, establishing a strong reputation in the beverage market.",
    firstLineSecondContent:'In September 2023, Tianlala officially opened its first franchise branch in Indonesia, located at Batavia Pantai Indah Kapuk. Within three months, Tianlala has already opened 8 branches in the Jakarta area.',
    fourthOneTitle:"Our Vision",
    fourthOneSecondContent:"LEADING THE FUTURE",
    fourthOneThirdContent:"OF TEA INDUSTRY",
    fourthTwoTitle:"Our Mission",
    fourthTwoSecondContent:"MAKING",
    fourthTwoThirdContent:"ENTREPRENEURSHIP SIMPLE",
    fourthThreeTitle:"Slogan",
    fourthThreeSecondContent:"SERVING",
    fourthThreeThirdContent:"FRANCHISEES, SERVING FAMILIES, SERVING CONSUMERS, SERVING SOCIETY",
    fourthFourthTitle:"Corporate Spirit",
    fourthFourthSecondContent:"CRISIS IN MIND",
    fourthFourthThirdContent:"CONTINUOUS INNOVATION, DARE TO THINK, DARE TO DO, DARE TO FIGHT",
    sixContentTitle:"TIANLALA’S HISTORY",
    content2015:'On January 8, 2015, the brand "甜啦啦" (Tianlala) was officially established and opened for franchise registration.',
    content2015B:" In that year, the number of outlets grew to 92.",
    content2018:"In 2018, Tianlala began organizing the development of the supply chain from the factory to each store. In the same year, a distribution warehouse was opened in Changsha.",
    content2018B:" The number of outlets that year expanded to 1000.",
    content2019:"In 2019, Tianlala started structuring information industry construction, developing an online operational platform. In the same year, a distribution warehouse was opened in Tianjin.",
    content2019B:" The number of outlets that year grew to 1800.",
    content2020:"In 2020, Tianlala opened a distribution warehouse in Zhengzhou. The number of outlets that year expanded to 3000.",
    content2020B:" During that year, sales reached 4.63 billion with a tax revenue of 20 million.",
    content2023:"In 2023, Tianlala established branches in Shanghai and Beijing to drive research and development strategies. In October of the same year, a foreign business division was founded, initiating expansion into the Indonesian market. ",
    content2023B:"The nationwide number of outlets grew to 7500. Estimated annual sales are expected to reach 2.5 billion with a tax revenue of 1.2 billion.",
    eightLineText:"Experience the essence of Tianlala as we invite you on a journey of discovery, where each sip tells a story of passion and excellence. From our meticulously curated selection of teas to our innovative marketing strategies, every aspect of our brand reflects our dedication to delighting our customers and exceeding their expectations.",
    sevenTitle:"WHY PARTNER WITH TIANLALA? ",
    content20:'OUTLETS IN INDONESIA',
    contentX:"PARTNERSHIPS",
    content7500:"STORES WORLDWIDE",
    content98:"CUSTOMER SATISFACTION",
    content11:"CUPS SOLD PER YEAR"
  },
  media:{
    title:"TIANLALA GALLERY",
    firstLineTitle:"TIANLALA fosters connections, where individuals gather, exchange smiles, and create memorable moments.",
  },
  coop:{
    title:"PARTNERSHIP JOINING PROCEDURE",
    firstTitle:"CONSULTATION",
    firstContent:"Understanding Tianlala better through phone/online communication.",
    secondTitle:"MEETING",
    secondContent:"Scheduling an appointment to visit Tianlala's headquarters.",
    thirdTitle:"DISCUSSION",
    thirdContent:"Engaging in discussions to learn the details of joining Tianlala.",
    fourthTitle:"STORE LOCATION EVALUATION",
    fourthContent:"Assessing suitable and prime locations for the store.",
    fivethTitle:"CONTRACT SIGNING",
    fivethContent:"Passing the headquarters' assessment and signing the contract.",
    sixthTitle:"STORE DESIGN",
    sixthContent:"Planning and designing the store's decor.",
    seventhTitle:"TRAINING",
    seventhContent:"Providing professional training on theories, products, equipment, etc.",
    engithTitle:"OFFICIAL STORE OPENING",
    engithContent:"Experts providing guidance and support in starting the business.",
    benefitsTitle:"BENEFITS OF",
    benefitsSecondTitle:"PARTNERING WITH US",
    affordableCosts:"Affordable Costs",
    expense:"Starting from management fees, machinery, and other associated expenses.",
    growth:"GROWTH AND MARKET",
    growthText:'In a span of 7 years, Tianlala has established over 7,500 stores with annual sales of 110 million cups.',
    marketText:'High-quality beverages at affordable prices.',
    productService:'PRODUCTS AND SERVICES',
    productText:"We introduce popular domestic products, with 2-4 new products launched per year.",
    serviceText:"Regional managers regularly visit stores 2-4 times a month for staff training, store management, and equipment maintenance.",
    companyText:"The headquarters also aids store activities such as online ordering, graphic design, promotional materials, etc."
  },
  home:{
    sixFirstTitle:'Angga Guntina',
    sixFirstTips:"Friendly service, comfortable place and a very varied menu and highly recommended",
    sixFirstNotes:"Food: 5/5 | Service: 5/5 | Atmosphere: 5/5",
    sixSecondTitle:'Cici Mitha Audita',
    sixSecondTips:"For brothers and sisters, the waiters are very friendly, fast, don't have to wait long, the place is really comfortable for gathering with friends or family",
    sixSecondNotes:"Food: 5/5 | Service: 5/5 | Atmosphere: 5/5 ",
    sixThirdTitle:'Go Eviyanti',
    sixThirdTips:"The yoghurt ice cream is really delicious, the milk tea is very satisfying and the prices are affordable. Service is very responsive. The place is really cool and comfortable",
    sixThirdNotes:"Food: 5/5 | Service: 5/5 | Atmosphere: 5/5",
    sixFourthTitle:'Nurul Aprillia',
    sixFourthTips:"The place is clean, very comfortable for relaxing, the employees are very friendly and polite, the menu is delicious, everything is fresh, great",
    sixFourthNotes:"Food: 5/5 | Service: 5/5 | Atmosphere: 5/5",
  }
};
