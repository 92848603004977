// 定义英文语言包对象
export default {
  hotspot: "india",
  ABOUT:"TENTANG KAMI",
  OURMENU:"MENU KAMI",
  MEDIA:"MEDIA",
  COOPERATION:"KERJA SAMA",
  CONTACT:"KONTAK",
  discoverNotes:'Tianlala adalah merek teh bermerk di bawah kemakmuran PT Hui Wang',
  aboutSecondContent:'Tianlala, di mana setiap tegukan mengisahkan semangat dan keunggulan yang tak tertandingi. Dari pilihan teh terbaik kami hingga strategi pemasaran yang inovatif, setiap aspek dari merek Tianlala mencerminkan dedikasi kami untuk memuaskan pelanggan dan melampaui harapan mereka.',
  bestSeller:'BEST SELLER KAMI',
  bestSellerSecond:"Ember keluarga yang bahagi",
  aboutMoreBtnText:'See More Menu',
  fiveTitle:'OUTLET TOKO KAMI DI INDONESIA',
  fiveNotes:'Pantai Indah Kapuk, Muara Karang, Kota Tua, Mangga Besar, Pasar Baru, Taman Ratu, Tanjung Duren, Klender, Bintara, Jatiwaringin, Ciamis, Mall Artha Gading, BSD',
  gooleReview:'GOOGLE REVIEW',
  helenaN:"Helena N",
  makanan:'Makanan: 5/5 | Layanan: 5/5 | Suasana: 5/5',
  servingIce:"Serving ice cream and tea based desserts, fresh and very affordable. Consists of 4 floors, spacious place, plenty tables to sit, good service, cold Air Conditioner which is very good to have in this sunny hot PIK area.",
  about:{
    firstLineTitle:'SELAMAT DATANG DI TIANLALA WONDERLAND',
    firstLineTips:"Tianlala adalah merek kemitraan teh di bawah PT Hui Wang Prosperity",
    firstLineContent:"yang dibuka untuk kemitraan pada tahun 2015. Tianlala adalah organisasi rantai waralaba nasional Cina yang fokus pada produksi dan penjualan teh buah segar. Karena kualitas minuman yang baik, rasa yang luar biasa, dan harga yang cukup terjangkau, Tianlala dengan cepat menjadi merek terkemuka di pasar Cina dengan lebih dari 7.500 toko yang telah beroperasional di seluruh dunia, membentuk reputasi yang baik di pasar minuman. ",
    firstLineSecondContent:"Pada bulan September 2023, Tianlala resmi membuka cabang pertamanya di Indonesia yang berlokasi di Ruko Batavia Pantai Indah Kapuk. Dalam waktu 3 bulan, Tianlala sudah membuka 8 cabang di sekitar Jakarta.",
    fourthOneTitle:"Visi Kami",
    fourthOneSecondContent:"MEMIMPIN MASA DEPAN",
    fourthOneThirdContent:" INDUSTRI TEH",
    fourthTwoTitle:"Misi Kami",
    fourthTwoSecondContent:"MEMBUAT",
    fourthTwoThirdContent:"KEWIRAUSAHAAN MENJADI MUDAH",
    fourthThreeTitle:"Slogan",
    fourthThreeSecondContent:"MELAYANI",
    fourthThreeThirdContent:"MITRA, MELAYANI KELUARGA, MELAYANI KONSUMEN, MELAYANI MASYARAKAT",
    fourthFourthTitle:"Semangat Perusahaan",
    fourthFourthSecondContent:"TERUS BERINOVASI",
    fourthFourthThirdContent:"BERANI BERPIKIR, BERANI BERBUAT, BERANI BERJUANG",
    sixContentTitle:"SEJARAH TIANLALA",
    content2015:'Pada tanggal 8 Januari 2015, merek "甜啦啦" (Tianlala) secara resmi didirikan dan dibuka untuk pendaftaran waralaba.',
    content2015B:' Pada tahun itu, jumlah gerai berkembang menjadi 92.',
    content2018:"Pada tahun 2018, Tianlala mulai menata pembangunan rantai pasokan dari pabrik sampai masing masing toko. Pada tahun yang sama, membuka gudang distribusi di Changsha.",
    content2018B:" Jumlah gerai pada tahun itu berkembang menjadi 1000.",
    content2019:"Pada tahun 2019, Tianlala mulai menata konstruksi industri informasi,  mengembangkan platform operasional online. Pada tahun yang sama, membuka gudang distribusi di Tianjin.",
    content2019B:' Jumlah gerai pada tahun itu berkembang menjadi 1800. ',
    content2020:"Pada tahun 2020, Tianlala membuka gudang distribusi di Zhengzhou. ",
    content2020B:' Jumlah gerai pada tahun itu berkembang menjadi 3000. Selama tahun tersebut, penjualan mencapai 4.63 miliar dengan pendapatan pajak sebesar 20 juta.',
    content2023:"Pada tahun 2023, Tianlala mendirikan cabang di Shanghai dan Beijing untuk strategi penelitian dan pengembangan. Pada bulan Oktober tahun yang sama, didirikan divisi bisnis luar negeri dan memulai ekspansi ke pasar Indonesia. Jumlah gerai di seluruh negeri berkembang menjadi 7500. ",
    content2023B:"Diperkirakan penjualan tahunan mencapai 2.5 miliar dengan pendapatan pajak sebesar 1.2 miliar",
    eightLineText:"Tianlala, di mana setiap tegukan mengisahkan semangat dan keunggulan yang tak tertandingi. Dari pilihan teh terbaik kami hingga strategi pemasaran yang inovatif, setiap aspek dari merek Tianlala mencerminkan dedikasi kami untuk memuaskan pelanggan dan melampaui harapan mereka.",
    sevenTitle:"KENAPA BEKERJASAMA DENGAN TIANLALA?",
    content20:"CABANG DI INDONESIA",
    contentX:"PARTNER KERJASAMA",
    content7500:"TOKO DI SELURUH DUNIA",
    content98:"KEPUASAN PELANGGAN",
    content11:"GELAS TERJUAL SETIAP TAHUN",
  },
  media:{
    title:"GALERI TIANLALA",
    firstLineTitle:"TIANLALA menginspirasi kebersamaan, di mana orang-orang berkumpul, saling berbagi senyuman, dan menciptakan momen-momen yang tak terlupakan.",  
  },
  coop:{
    title:"TATA CARA BERGABUNG KERJASAMA",
    firstTitle:"KONSULTASI",
    firstContent:"Memahami tentang Tianlala lebih baik melalui telepon/online.",
    secondTitle:"MEETING",
    secondContent:"Membuat janji temu untuk mengunjungi kantor pusat Tianlala.",
    thirdTitle:"DISKUSI",
    thirdContent:"Diskusi untuk mempelajari detail bergabung ke Tianlala.",
    fourthTitle:"EVALUASI LOKASI TOKO",
    fourthContent:"Mengevaluasi lokasi yang bagus dan cocok.",
    fivethTitle:"TANDA TANGAN KONTRAK",
    fivethContent:"Lulus penilaian kantor pusat dan menandatangani kontrak.",
    sixthTitle:"DESAIN TOKO",
    sixthContent:"Perencanaan dan desain dekorasi toko.",
    seventhTitle:"PELATIHAN",
    seventhContent:"Memberikan pelatihan profesional tentang teori, produk, peralatan, dll.",
    engithTitle:"RESMI BUKA TOKO",
    engithContent:"Spesialis memberikan bimbingan dan bantuan dalam membuka usaha",
    benefitsTitle:"KEUNTUNGAN BERGABUN",
    benefitsSecondTitle:"DENGAN KAMI",
    affordableCosts:"Biaya Terjangkau",
    expense:"Mulai dari biaya manajemen, mesin maupun biaya yang lainnya",
    growth:'PERTUMBUHAN DAN PASANGA',
    growthText:"Dalam kurun waktu 7 tahun, Tianlala sudah membuka lebih dari 7.500 toko dengan penjualan 110,000,000 gelas/tahun",
    marketText:"Minuman berkualitas dengan harga terjangkau",
    productService:'PRODUK DAN LAYANAN',
    productText:"Kami rutin mengeluarkan produk yang populer dengan rasa lokal, 2-4 produk baru / Tahun",
    serviceText:"Manager area teratur mengunjungi toko 2-4 kali / bulan dalam rangka Pelatihan Staf, Manajemen Toko, Pemeliharaan Mesin.",
    companyText:"Kantor pusat juga membantu aktivitas toko seperti Pemesanan Online, Design Grafis, Materi Promosi, dll."
  },
  home:{
    sixFirstTitle:'Angga Guntina',
    sixFirstTips:"Pelayanan ramah, tempat nyaman dan menu yang sangat bervariasi dan sangat rekomendasi",
    sixFirstNotes:"Makanan: 5/5 | Layanan: 5/5 | Suasana: 5/5 ",
    sixSecondTitle:'Cici Mitha Audita',
    sixSecondTips:"Untuk kakak-kakak pelayannya ramah banget, cepat ga pakai nunggu lama, tempatnya nyaman bisa banget buat kumpul bareng temen atau keluarga",
    sixSecondNotes:"Makanan: 5/5 | Layanan: 5/5 | Suasana: 5/5",
    sixThirdTitle:'Go Eviyanti',
    sixThirdTips:"Enak bgt es krim yoghurtnya, teh susu puas banyak harga terjangkau. Pelayanan sangat responsive. Tempatnya adem nyaman bgt",
    sixThirdNotes:"Makanan: 5/5 | Layanan: 5/5 | Suasana: 5/5",
    sixFourthTitle:'Nurul Aprillia',
    sixFourthTips:"Tempatnya bersih nyaman banget buat santai,karyawannya ramah sopan banget,menunya enak semua frreshhh mantap",
    sixFourthNotes:"Makanan: 5/5 | Layanan: 5/5 | Suasana: 5/5",
  }
};
