import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n";
import en from "./langurage/en";
import id from "./langurage/id";
import VueLazyload from "vue-lazyload"
const messages = {
  en,
  id,
};


// 创建实例对象
const i18n = createI18n({
  legacy: false, // 设置为 false，启用 composition API 模式
  messages,
  locale: "en",
});
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
const app = createApp(App);
app.use(VueLazyload);
app.use(router);
app.use(i18n);
app.mount("#app");
